import React from 'react'

import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Section from '../layouts/section'
import Wrapper from '../layouts/wrapper'

import Illustration from '../svgs/error-404.svg'

const PageSection = styled(Section)`
  position: relative;
  padding-top: 120px;
  padding-bottom: 48px;

  @media (min-width: 1024px) {
    padding-top: 200px;
    padding-bottom: 120px;
  }
`

const PageWrapper = styled(Wrapper)`
  max-width: 576px;
`

const IllustrationWrapper = styled.div`
  margin-bottom: 48px;
`

const Heading = styled.h1`
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.25;
  text-align: center;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <PageSection>
      <PageWrapper>
        <IllustrationWrapper>
          <Illustration />
        </IllustrationWrapper>
        <Heading>Oops, the page you found has lost in the 404 forests. Please try another page with the above navigation or contact us for more information.</Heading>
      </PageWrapper>
    </PageSection>
  </Layout>
)

export default NotFoundPage
